import React, { useEffect, useReducer, useRef } from 'react';
import { graphql } from 'gatsby';
import DatePicker from 'react-datepicker';
import {
  Layout, GeneralHero, EventCard,
} from '@components';
import { Content, ContentGutter, IntroWrapper } from '@styles';
import { Arrow } from '@styles/icons';
import { getKeyValue, compileRichText } from '@utils';
import { DARK, EVENT_TYPES, ZIP_REGEXP } from '@utils/vars';
import {
  SearchFilterForm, FormItem, FormItemLabel, FormSubmit, EventResults, FilterItem,
  FormInput, FormSelect, FormInputError, PaginationButton, PaginationItems, FilterWrapper,
} from './events-landing.styles';

const initialState = {
  startDate: new Date(),
  iniStartDate: new Date(),
  endDate: '',
  eventType: 'ALL',
  zip: '',
  event: {},
  zipError: '',
  errorState: false,
  isLoading: false,
  page: 1,
  currentFilters: {},
};

function reducer(state, action) {
  return ({
    ...state,
    ...action,
  });
}

const formatDate = (date) => (`${(date.getMonth() > 8) ? (date.getMonth() + 1) : (`0${date.getMonth() + 1}`)}/${(date.getDate() > 9) ? date.getDate() : (`0${date.getDate()}`)}/${date.getFullYear()}`);

const isToday = (someDate) => {
  const today = new Date();
  return someDate.getDate() === today.getDate()
    && someDate.getMonth() === today.getMonth()
    && someDate.getFullYear() === today.getFullYear();
};

const EventsLandingSearch = ({ eventListingEventTypeOptions }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const searchRef = useRef();
  const {
    startDate, endDate, eventType, zip, zipError, events, currentFilters,
    iniStartDate, errorState, isLoading,
  } = state;
  const perPage = 200;

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!zipError) {
      const params = [`per_page=${perPage}&sort=timeslot_start`];
      const currentSetFilters = {};
      if (startDate) {
        const startDateFilter = isToday(startDate) ? 'now' : Math.round(startDate.getTime() / 1000);
        params.push(`timeslot_start=gte_${startDateFilter}`);
      }
      if (endDate) {
        params.push(`timeslot_start=gte_${Math.round(endDate.getTime() / 1000)}`);
        currentSetFilters.dateFilter = `${formatDate(startDate)}-${formatDate(endDate)}`;
      }
      if (zip && !zipError) {
        params.push(`zipcode=${zip}`);
        currentSetFilters.zip = zip;
      }
      if (eventType && eventType !== 'ALL') {
        params.push(`event_types=${eventType}`);
        currentSetFilters.eventType = eventType;
      }
      fetch(`${process.env.GATSBY_MOBILIZE_EVENTS}${params.length > 0 ? `?${params.join('&')}` : ''}`)
        .then((res) => res.json())
        .then((d) => {
          dispatch({
            events: d,
            isLoading: false,
            currentFilters: currentSetFilters,
          });
        })
        .catch((error) => {
          console.log(error);
          dispatch({
            errorState: true,
            isLoading: false,
          });
        });
    }
  };

  const handleZipBlur = (event) => {
    if (!ZIP_REGEXP.test(event.target.value) && event.target.value) {
      dispatch({ zipError: 'Please enter a valid ZIP code.' });
    } else {
      dispatch({ zipError: '' });
    }
  };

  const handlePagination = (e, url, type) => {
    e.preventDefault();
    if (url) {
      const newPage = type === 'next' ? state.page + 1 : state.page - 1;
      fetch(url)
        .then((res) => res.json())
        .then((d) => {
          dispatch({
            events: d,
            isLoading: false,
            page: newPage,
          });
          if (searchRef.current) {
            searchRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch({
            errorState: true,
            isLoading: false,
          });
        });
    }
  };

  useEffect(() => {
    const params = [`per_page=${perPage}&sort=timeslot_start`];
    params.push('timeslot_start=gte_now');

    fetch(`${process.env.GATSBY_MOBILIZE_EVENTS}${params.length > 0 ? `?${params.join('&')}` : ''}`)
      .then((res) => res.json())
      .then((d) => {
        dispatch({
          events: d,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          errorState: true,
          isLoading: false,
        });
      });
  }, []);

  const eventTypeOptions = Object.keys(EVENT_TYPES)
    .filter((key) => eventListingEventTypeOptions.includes(key))
    .reduce((obj, key) => {
      obj[key] = EVENT_TYPES[key];
      return obj;
    }, {});

  const {
    data: edata, count, next, previous,
  } = events || {};

  const handleRemoveFilter = (type) => {
    const newSetFilters = { ...currentFilters };
    delete newSetFilters[type];
    const params = [`per_page=${perPage}&sort=timeslot_start`];

    if (type === 'dateFilter') {
      dispatch({
        startDate: iniStartDate,
        endDate: '',
        currentFilters: { ...newSetFilters },
      });
      params.push('timeslot_start=gte_now');
      if (zip && !zipError) {
        params.push(`zipcode=${zip}`);
      }
      if (eventType && eventType !== 'ALL') {
        params.push(`event_types=${eventType}`);
      }
    }
    if (type === 'zip') {
      dispatch({
        zip: '',
        currentFilters: { ...newSetFilters },
      });
      if (startDate) {
        params.push(`timeslot_start=gte_${Math.round(startDate.getTime() / 1000)}`);
      }
      if (endDate) {
        params.push(`timeslot_start=gte_${Math.round(endDate.getTime() / 1000)}`);
      }
      if (eventType && eventType !== 'ALL') {
        params.push(`event_types=${eventType}`);
      }
    }
    if (type === 'eventType') {
      dispatch({
        eventType: 'ALL',
        currentFilters: { ...newSetFilters },
      });
      if (startDate) {
        params.push(`timeslot_start=gte_${Math.round(startDate.getTime() / 1000)}`);
      }
      if (endDate) {
        params.push(`timeslot_start=gte_${Math.round(endDate.getTime() / 1000)}`);
      }
      if (zip && !zipError) {
        params.push(`zipcode=${zip}`);
      }
    }

    fetch(`${process.env.GATSBY_MOBILIZE_EVENTS}${params.length > 0 ? `?${params.join('&')}` : ''}`)
      .then((res) => res.json())
      .then((d) => {
        dispatch({
          events: d,
          isLoading: false,
          currentFilters: newSetFilters,
          page: 1,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          errorState: true,
          isLoading: false,
        });
      });
  };

  const sortedData = (edata && !zip) ? edata.sort((a, b) => {
    const compIniDate = Math.round(iniStartDate.getTime() / 1000);
    const newTimeslotsA = a.timeslots.filter((el) => el.end_date > compIniDate);
    const newTimeslotsB = b.timeslots.filter((el) => el.end_date > compIniDate);
    const timeLengthA = newTimeslotsA && newTimeslotsA.length;
    const startDateA = timeLengthA > 0 && newTimeslotsA[0].start_date;
    const timeLengthB = newTimeslotsB && newTimeslotsB.length;
    const startDateB = timeLengthB > 0 && newTimeslotsB[0].start_date;
    return new Date(startDateA) - new Date(startDateB);
  }) : edata;

  return (
    <React.Fragment>
      <SearchFilterForm ref={searchRef} onSubmit={handleSubmit}>
        <FormItem>
          <FormItemLabel htmlFor="start-date">Start Date</FormItemLabel>
          <DatePicker
            selected={startDate}
            onChange={(date) => dispatch({ startDate: date })}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            minDate={new Date()}
            placeholderText="MM/DD/YYYY"
            className="date-picker-wu"
            id="start-date"
          />
        </FormItem>
        <FormItem>
          <FormItemLabel htmlFor="end-date">End Date</FormItemLabel>
          <DatePicker
            selected={endDate}
            onChange={(date) => dispatch({ endDate: date })}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="MM/DD/YYYY"
            className="date-picker-wu"
            id="end-date"
          />
        </FormItem>
        {eventTypeOptions && (
          <FormItem>
            <FormItemLabel htmlFor="event-type">Event Type</FormItemLabel>
            <FormSelect
              id="event-type"
              value={eventType}
              onChange={(e) => dispatch({ eventType: e.target.value })}
            >
              <option key="ALL" value="ALL">All</option>
              {Object.keys(eventTypeOptions).map((key) => (
                <option key={key} value={key}>{eventTypeOptions[key]}</option>
              ))}
            </FormSelect>
          </FormItem>
        )}
        <FormItem>
          <FormItemLabel htmlFor="zipcode">ZIP Code</FormItemLabel>
          <FormInput
            hasError={!!zipError}
            type="text"
            pattern="[0-9]{5}"
            value={zip}
            onChange={(e) => dispatch({ zip: e.target.value })}
            onBlur={handleZipBlur}
            aria-describedby="zip-error"
          />
          {zipError && (
            <FormInputError id="zip-error" aria-live="assertive">{zipError}</FormInputError>
          )}
        </FormItem>
        <FormSubmit type="submit">Search</FormSubmit>
      </SearchFilterForm>
      <React.Fragment>
        {edata && (
          <React.Fragment>
            {edata.length > 0 ? (
              <EventResults aria-live="assertive">
                {count > perPage
                  ? `Showing events ${(perPage * (state.page - 1)) + 1}-${edata.length + (perPage * (state.page - 1))} of ${count}`
                  : `Showing ${edata.length} event${edata.length > 1 ? 's' : ''}`}
              </EventResults>
            ) : (
              <EventResults aria-live="assertive">
                {`Showing ${edata.length} events`}
              </EventResults>
            )}
          </React.Fragment>
        )}
        {currentFilters && (
        <FilterWrapper>
          {startDate && endDate && currentFilters.dateFilter && (
          <FilterItem
            onClick={() => handleRemoveFilter('dateFilter')}
            type="button"
          >
            {`${formatDate(startDate)} - ${formatDate(endDate)}`}
          </FilterItem>
          )}
          {zip && currentFilters.zip && (
          <FilterItem
            onClick={() => handleRemoveFilter('zip')}
            type="button"
          >
            {zip}
          </FilterItem>
          )}
          {eventType && eventType !== 'ALL' && currentFilters.eventType && (
          <FilterItem
            onClick={() => handleRemoveFilter('eventType')}
            type="button"
          >
            {eventTypeOptions[eventType]}
          </FilterItem>
          )}
        </FilterWrapper>
        )}
      </React.Fragment>
      {sortedData && (
        <ul>
          {sortedData.map((event, i) => (
            <EventCard
              text={event.title}
              href={event.browser_url}
              // eslint-disable-next-line react/no-array-index-key
              key={`${i}-event`}
              title={event.title}
              type={event.event_type}
              location={event.address_visibility === 'PUBLIC' && event.location}
              timeslots={event.timeslots}
              virtual={event.is_virtual}
              description={event.description}
              startDate={startDate}
              iniStartDate={iniStartDate}
            />
          ))}
        </ul>
      )}
      {(next || previous) && (
        <nav role="navigation" aria-label="Pagination">
          <PaginationItems className="pagination">
            <li className={`pagination-item pagination-item--prev ${previous ? '' : 'disabled'}`}>
              <PaginationButton previous disabled={!previous} onClick={(e) => handlePagination(e, previous, 'prev')} type="button">
                <Arrow strokeColor="secondaryHighlight" height="13px" width="14.5px" />
                Previous
              </PaginationButton>
            </li>
            <li className={`pagination-item pagination-item--next ${next ? '' : 'disabled'}`}>
              <PaginationButton next disabled={!next} onClick={(e) => handlePagination(e, next, 'next')} type="button">
                Next
                <Arrow strokeColor="secondaryHighlight" height="13px" width="14.5px" />
              </PaginationButton>
            </li>
          </PaginationItems>
        </nav>
      )}
    </React.Fragment>
  );
};

const EventsLandingTemplate = ({ location, data, pageContext }) => {
  const {
    site, page, globalSettings,
  } = data;

  const { locale } = pageContext;
  const { title: siteTitle, social } = getKeyValue(site, 'siteMetadata') || {};
  const {
    pageTitle, contentEntry, languages, pageDescription, metaImage, pageUrl,
    disableDonateBar, disableAlertBar, disableSplash, doNotIndex,
  } = page || {};
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};
  const {
    title, intro: summary, heroImage,
  } = contentEntry || {};
  const {
    navigation, enableAlertBar, alertBarLink, alertBarText, footerDisclaimer,
    footerSecondaryLinks, footerPrimaryLinks, footerSocialLinks, footerCopyright, footerAddress,
    enableDonateBar, donationBar, donateBarDisclaimer, enableSplash, splash, footerForm,
    footerEmails, footerPhoneNumber, formLabelLanguageOverrides, footerDonateByMail,
  } = globalSettings || {};
  const hasImg = heroImage && heroImage.fluid;

  const footerProps = {
    footerCopyright,
    footerDisclaimer,
    footerSocialLinks,
    footerPrimaryLinks,
    footerSecondaryLinks,
    footerForm,
    footerAddress,
    footerEmails,
    footerPhoneNumber,
    formLabelLanguageOverrides,
    footerDonateByMail,
  };

  const donateBarProps = {
    ...donationBar,
    donateBarDisclaimer,
  };

  const alertBarProps = {
    alertBarLink,
    alertBarText,
  };

  const splashProps = {
    ...splash,
    donateBarDisclaimer,
    formLabelLanguageOverrides,
  };

  return (
    <Layout
      location={location}
      navigation={navigation}
      languages={languages}
      footerProps={footerProps}
      donateBarProps={donateBarProps}
      alertBarProps={alertBarProps}
      enableAlertBar={enableAlertBar && !disableAlertBar}
      enableDonateBar={enableDonateBar && !disableDonateBar}
      enableSplash={enableSplash && !disableSplash}
      metaDescription={pageDescription}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${pageUrl}`}
      metaTitle={`${pageTitle} | ${siteTitle}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      splashProps={splashProps}
      locale={locale}
      fullWidth
      navTheme={DARK}
      mainTheme={DARK}
      doNotIndex={doNotIndex}
    >
      <GeneralHero
        title={title}
        heroImage={heroImage}
        intro={summary}
      />
      <ContentGutter>
        <Content>
          {!hasImg && summary && (
            <IntroWrapper>
              {compileRichText({ textTheme: 'primary', size: 'large', locale })(summary)}
            </IntroWrapper>
          )}
          <EventsLandingSearch {...contentEntry} />
        </Content>
      </ContentGutter>
    </Layout>
  );
};

export default EventsLandingTemplate;

export const eventsLandingPageQuery = graphql`
  query EventsLandingBySlug($slug: String!, $locale: String!) {
    site {
      ...SiteMetadata
    }
    page: contentfulPage(pageUrl: { eq: $slug }) {
      ...PageMetadata
      contentEntry {
        ... on ContentfulNewsItemEndorsementsLanding {
          title
          heroImage {
            description
            id
            fluid(quality: 90, maxWidth: 974) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          intro {
            raw
          }
          eventListingEventTypeOptions
        }
      }
    }
    globalSettings: contentfulGlobalSettings(language: { eq: $locale }) {
      ...GlobalSettings
    }
  }
`;
