import styled, { css } from 'styled-components';
import { darken, lighten } from 'polished';
import { createBreakpoint } from '@styles';

export const SearchFilterForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1 1 0;
  margin-bottom: 20px;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    flex-direction: row;
    margin-bottom: 30px;
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    flex-direction: row;
  `)}
`;

export const FormItemLabel = styled.label`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.white};
  font-weight: 700;
`;

export const FormInput = styled.input`
  width: 100%;
  height: 50px;
  border: 2px solid ${({ theme }) => theme.palette.secondaryHighlight};
  font-size: 22px;
  padding: .5rem;
  color: ${({ theme }) => theme.palette.primary};
  background-color: ${({ theme }) => lighten(0.4, theme.palette.secondaryHighlight)};
  transition: background 0.3s ease-in-out;

  &:focus {
    background-color: ${({ theme }) => lighten(0.35, theme.palette.secondaryHighlight)};
  }

  ${({ hasError }) => hasError && css`
    border: 4px solid ${({ theme }) => theme.palette.secondaryHighlight};
    background-color: ${({ theme }) => lighten(0.3, theme.palette.secondaryHighlight)};
  `}
`;

export const FormInputError = styled.span`
  display: block;
  color: ${({ theme }) => theme.palette.secondaryHighlight};
  font-weight: 700;
`;

export const FormSelect = styled.select`
  width: 100%;
  height: 50px;
  border: 2px solid ${({ theme }) => theme.palette.secondaryHighlight};
  font-size: 22px;
  padding: .5rem;
  color: ${({ theme }) => theme.palette.primary};
  background-color: ${({ theme }) => lighten(0.4, theme.palette.secondaryHighlight)};
  transition: background 0.3s ease-in-out;

  &:focus {
    background-color: ${({ theme }) => lighten(0.35, theme.palette.secondaryHighlight)};
  }
`;

export const FormSubmit = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  text-transform: uppercase;
  font-weight: 700;
  justify-content: center;
  font-size: 16px;
  letter-spacing: 0.16em;
  border-radius: ${({ theme }) => theme.button.radius};
  line-height: 1.2;
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.palette.primary};
  background-color: ${({ theme }) => theme.palette.highlight};
  font-size: 16px;
  padding: 8px 25px 9px 25px;
  margin-top: 5px;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    font-size: 18px;
    padding: 11px 45px 12px 45px;
    width: fit-content;
    min-width: fit-content;
  `)}

  &:hover {
    background-color: ${({ theme }) => theme.palette.secondaryHighlight};
  }
`;

export const FormItem = styled.div`
  margin-bottom: 16px;
  width: 100%;

  .date-picker-wu {
    width: 100%;
    height: 50px;
    border: 2px solid ${({ theme }) => theme.palette.secondaryHighlight};
    font-size: 22px;
    padding: .5rem;
    color: ${({ theme }) => theme.palette.primary};
    background-color: ${({ theme }) => lighten(0.4, theme.palette.secondaryHighlight)};

    &:focus {
      background-color: ${({ theme }) => lighten(0.35, theme.palette.secondaryHighlight)};
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
  flex-direction: row;
    width: calc(50% - 8px);

    &:nth-child(odd) {
      margin-right: 16px;
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    margin-bottom: 20px;
    width: calc(50% - 10px);

    &:nth-child(odd) {
      margin-right: 20px;
    }
  `)}

  .react-datepicker-wrapper {
    display: block;
    padding: 0;
    border: 0;
  }

  .react-datepicker {
    font-family: ${({ theme }) => theme.font};
    font-size: 0.8rem;
    background-color: ${({ theme }) => theme.palette.white};
    color: ${({ theme }) => theme.palette.primary};
    border: 1px solid ${({ theme }) => theme.palette.secondaryHighlight};
    border-radius: 0;
    display: inline-block;
    position: relative;
  }

  .react-datepicker--time-only {
    .react-datepicker__triangle {
      left: 35px;
    }

    .react-datepicker__time-container {
      border-left: 0;
    }

    .react-datepicker__time,
    .react-datepicker__time-box {
      border-bottom-left-radius: 0.3rem;
      border-bottom-right-radius: 0.3rem;
    }
  }

  .react-datepicker__triangle {
    position: absolute;
    left: 50px;
  }

  .react-datepicker-popper {
    z-index: 1;

    &[data-placement^="bottom"] {
      margin-top: 8px + 2px;

      .react-datepicker__triangle {
        top: 0;
        margin-top: -8px;
        margin-left: -8px;
        position: absolute;

        &,
        &::before {
          border-top: none;
          border-bottom-color: #f0f0f0;
          box-sizing: content-box;
          position: absolute;
          border: 8px solid transparent;
          height: 0;
          width: 1px;
        }

        &::before {
          content: "";
          z-index: -1;
          border-width: 8px;
          left: -8px;
          border-bottom-color: #aeaeae;
          top: -1px;
          border-bottom-color: #aeaeae;
        }
      }
    }

    &[data-placement="bottom-end"],
    &[data-placement="top-end"] {
      .react-datepicker__triangle {
        left: auto;
        right: 50px;
      }
    }

    &[data-placement^="top"] {
      margin-bottom: 8px + 2px;

      .react-datepicker__triangle {
        bottom: 0;
        margin-bottom: -8px;
        margin-left: -8px;
        position: absolute;

        &,
        &::before {
          border-bottom: none;
          border-top-color: #fff;
          border-width: 8px;
          left: -8px;
          border-bottom-color: #aeaeae;
          top: -1px;
          border-bottom-color: #aeaeae;
        }

        &::before {
          content: "";
          z-index: -1;
          border-width: 8px;
          left: -8px;
          border-bottom-color: #aeaeae;
          bottom: -1px;
          border-top-color: #aeaeae;
        }
      }
    }

    &[data-placement^="right"] {
      margin-left: 8px;

      .react-datepicker__triangle {
        left: auto;
        right: 42px;
      }
    }

    &[data-placement^="left"] {
      margin-right: 8px;

      .react-datepicker__triangle {
        left: 42px;
        right: auto;
      }
    }
  }

  .react-datepicker__header {
    text-align: center;
    background-color: ${({ theme }) => theme.palette.secondaryLightHighlight};
    border-bottom: 1px solid ${({ theme }) => theme.palette.secondaryHighlight};
    border-top-left-radius: 0;
    padding-top: 8px;
    position: relative;

    &--time {
      padding-bottom: 8px;
      padding-left: 5px;
      padding-right: 5px;

      &:not(&--only) {
        border-top-left-radius: 0;
      }
    }

    &:not(&--has-time-select) {
      border-top-right-radius: 0;
    }
  }

  .react-datepicker__year-dropdown-container--select,
  .react-datepicker__month-dropdown-container--select,
  .react-datepicker__month-year-dropdown-container--select,
  .react-datepicker__year-dropdown-container--scroll,
  .react-datepicker__month-dropdown-container--scroll,
  .react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 2px;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    margin-top: 0;
    color: ${({ theme }) => theme.palette.primary};
    font-weight: bold;
    font-size: 0.8rem * 1.18;
  }

  .react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .react-datepicker__navigation {
    background: none;
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 10px;
    width: 0;
    padding: 0;
    border: 0.45rem solid transparent;
    z-index: 1;
    height: 10px;
    width: 10px;
    text-indent: -999em;
    overflow: hidden;

    &--previous {
      left: 10px;
      border-right-color: #ccc;

      &:hover {
        border-right-color: #ccc;
      }

      &--disabled,
      &--disabled:hover {
        border-right-color: #ccc;
        cursor: default;
      }
    }

    &--next {
      right: 10px;
      border-left-color: #ccc;
      &--with-time:not(&--with-today-button) {
        right: 95px;
      }

      &:hover {
        border-left-color: #ccc;
      }

      &--disabled,
      &--disabled:hover {
        border-left-color: #ccc;
        cursor: default;
      }
    }

    &--years {
      position: relative;
      top: 0;
      display: block;
      margin-left: auto;
      margin-right: auto;

      &-previous {
        top: 4px;
        border-top-color: #ccc;

        &:hover {
          border-top-color: #ccc;
        }
      }

      &-upcoming {
        top: -4px;
        border-bottom-color: #ccc;

        &:hover {
          border-bottom-color: #ccc;
        }
      }
    }
  }

  .react-datepicker__month-container {
    float: left;
  }

  .react-datepicker__year {
    margin: 0.4rem;
    text-align: center;
    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      max-width: 180px;
    }
    .react-datepicker__year-text {
      display: inline-block;
      width: 4rem;
      margin: 2px;
    }
  }

  .react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
    .react-datepicker__month-text,
    .react-datepicker__quarter-text {
      display: inline-block;
      width: 4rem;
      margin: 2px;
    }
  }

  .react-datepicker__input-time-container {
    clear: both;
    width: 100%;
    float: left;
    margin: 5px 0 10px 15px;
    text-align: left;
    .react-datepicker-time__caption {
      display: inline-block;
    }
    .react-datepicker-time__input-container {
      display: inline-block;
      .react-datepicker-time__input {
        display: inline-block;
        margin-left: 10px;
        input {
          width: auto;
        }
        input[type="time"]::-webkit-inner-spin-button,
        input[type="time"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type="time"] {
          -moz-appearance: textfield;
        }
      }
      .react-datepicker-time__delimiter {
        margin-left: 5px;
        display: inline-block;
      }
    }
  }

  .react-datepicker__time-container {
    float: right;
    border-left: 1px solid ${({ theme }) => theme.palette.secondaryHighlight};
    width: 85px;

    &--with-today-button {
      display: inline;
      border: 1px solid #aeaeae;
      border-radius: 0.3rem;
      position: absolute;
      right: -72px;
      top: 0;
    }

    .react-datepicker__time {
      position: relative;
      background: white;
      border-bottom-right-radius: 0.3rem;

      .react-datepicker__time-box {
        width: 85px;
        overflow-x: hidden;
        margin: 0 auto;
        text-align: center;
        border-bottom-right-radius: 0.3rem;
        ul.react-datepicker__time-list {
          list-style: none;
          margin: 0;
          height: calc(195px + (#{1.7rem} / 2));
          overflow-y: scroll;
          padding-right: 0px;
          padding-left: 0px;
          width: 100%;
          box-sizing: content-box;

          li.react-datepicker__time-list-item {
            height: 30px;
            padding: 5px 10px;
            white-space: nowrap;
            &:hover {
              cursor: pointer;
              background-color: ${({ theme }) => theme.palette.secondaryLightHighlight};
            }
            &--selected {
              background-color: ${({ theme }) => theme.palette.primary};
              color: white;
              font-weight: bold;
              &:hover {
                background-color: ${({ theme }) => theme.palette.primary};
              }
            }
            &--disabled {
              color: #ccc;

              &:hover {
                cursor: default;
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }

  .react-datepicker__week-number {
    color: #ccc;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
    &.react-datepicker__week-number--clickable {
      cursor: pointer;
      &:hover {
        border-radius: 0;
        background-color: ${({ theme }) => theme.palette.secondaryLightHighlight};
      }
    }
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    white-space: nowrap;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: ${({ theme }) => theme.palette.primary};
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
  }

  .react-datepicker__month,
  .react-datepicker__quarter {
    &--selected,
    &--in-selecting-range,
    &--in-range {
      border-radius: 0;
      background-color: ${({ theme }) => theme.palette.primary};
      color: ${({ theme }) => theme.palette.white};

      &:hover {
        background-color: ${({ theme }) => theme.palette.primary};
      }
    }
    &--disabled {
      color: #ccc;
      pointer-events: none;
      &:hover {
        cursor: default;
        background-color: transparent;
      }
    }
  }

  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__quarter-text,
  .react-datepicker__year-text {
    cursor: pointer;

    &:hover {
      border-radius: 0;
      background-color: ${({ theme }) => theme.palette.secondaryLightHighlight};
    }

    &--today {
      font-weight: bold;
    }

    &--highlighted {
      border-radius: 0;
      background-color: ${({ theme }) => theme.palette.secondaryHighlight};
      color: ${({ theme }) => theme.palette.white};

      &:hover {
        background-color: ${({ theme }) => theme.palette.secondaryDarkHighlight};
      }

      &-custom-1 {
        color: magenta;
      }

      &-custom-2 {
        color: green;
      }
    }

    &--selected,
    &--in-selecting-range,
    &--in-range {
      border-radius: 0;
      background-color: ${({ theme }) => theme.palette.primary};
      color: ${({ theme }) => theme.palette.white};

      &:hover {
        background-color: ${({ theme }) => theme.palette.primary};
      }
    }

    &--in-range {
      background-color: ${({ theme }) => theme.palette.primary};
    }

    &--selected {
      font-weight: 700;
      background-color: ${({ theme }) => darken(0.1, theme.palette.primary)};
    }

    &--keyboard-selected {
      border-radius: 0;
      background-color: ${({ theme }) => theme.palette.primary};
      color: ${({ theme }) => theme.palette.white};

      &:hover {
        background-color: ${({ theme }) => theme.palette.primary};
      }
    }

    &--in-selecting-range:not(&--in-range) {
      background-color: ${({ theme }) => theme.palette.primary};
    }

    &--in-range:not(&--in-selecting-range) {
      .react-datepicker__month--selecting-range & {
        background-color: ${({ theme }) => theme.palette.secondaryLightHighlight};
        color: ${({ theme }) => theme.palette.primary};
      }
    }

    &--disabled {
      cursor: default;
      color: #ccc;

      &:hover {
        background-color: transparent;
      }
    }
  }

  .react-datepicker__month-text,
  .react-datepicker__quarter-text {
    &.react-datepicker__month--selected,
    &.react-datepicker__month--in-range,
    &.react-datepicker__quarter--selected,
    &.react-datepicker__quarter--in-range {
      &:hover {
        background-color: ${({ theme }) => theme.palette.primary};
      }
    }
    &:hover {
      background-color: ${({ theme }) => theme.palette.secondaryLightHighlight};
    }
  }

  .react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .react-datepicker__year-read-view,
  .react-datepicker__month-read-view,
  .react-datepicker__month-year-read-view {
    border: 1px solid transparent;
    border-radius: 0;

    &:hover {
      cursor: pointer;

      .react-datepicker__year-read-view--down-arrow,
      .react-datepicker__month-read-view--down-arrow {
        border-top-color: #ccc;
      }
    }

    &--down-arrow {
      bottom: 0;
      margin-bottom: -8px;
      margin-left: -8px;
      position: absolute;

      &,
      &::before {
        border-bottom: none;
        border-top-color: #fff;
        border-width: 8px;
        left: -8px;
        border-bottom-color: #aeaeae;
        top: -1px;
        border-bottom-color: #aeaeae;
      }

      &::before {
        content: "";
        z-index: -1;
        border-width: 8px;
        left: -8px;
        border-bottom-color: #aeaeae;
        bottom: -1px;
        border-top-color: #aeaeae;
      }
      border-top-color: #ccc;
      float: right;
      margin-left: 20px;
      top: 8px;
      position: relative;
      border-width: 0.45rem;
    }
  }

  .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown,
  .react-datepicker__month-year-dropdown {
    background-color: ${({ theme }) => theme.palette.secondaryLightHighlight};
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    z-index: 1;
    text-align: center;
    border-radius: 0;
    border: 1px solid ${({ theme }) => theme.palette.secondaryHighlight};

    &:hover {
      cursor: pointer;
    }

    &--scrollable {
      height: 150px;
      overflow-y: scroll;
    }
  }

  .react-datepicker__year-option,
  .react-datepicker__month-option,
  .react-datepicker__month-year-option {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:first-of-type {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &:last-of-type {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:hover {
      background-color: #ccc;

      .react-datepicker__navigation--years-upcoming {
        border-bottom-color: #ccc;
      }

      .react-datepicker__navigation--years-previous {
        border-top-color: #ccc;
      }
    }

    &--selected {
      position: absolute;
      left: 15px;
    }
  }

  .react-datepicker__close-icon {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0px 6px 0px 0px;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: table-cell;
    vertical-align: middle;

    &::after {
      cursor: pointer;
      background-color: ${({ theme }) => theme.palette.primary};
      color: ${({ theme }) => theme.palette.white};
      border-radius: 50%;
      height: 16px;
      width: 16px;
      padding: 2px;
      font-size: 12px;
      line-height: 1;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      content: "\00d7";
    }
  }

  .react-datepicker__today-button {
    background: ${({ theme }) => theme.palette.secondaryLightHighlight};
    border-top: 1px solid ${({ theme }) => theme.palette.secondaryHighlight};
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
  }

  .react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width: 3rem;
      line-height: 3rem;
    }

    // Resize for small screens
    @media (max-width: 400px), (max-height: 550px) {
      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        width: 2rem;
        line-height: 2rem;
      }
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header {
      font-size: 0.8rem * 1.8;
    }

    .react-datepicker__navigation {
      border: 1.8 * 0.45rem solid transparent;
    }

    .react-datepicker__navigation--previous {
      border-right-color: #ccc;

      &:hover {
        border-right-color: #ccc;
      }

      &--disabled,
      &--disabled:hover {
        border-right-color: #ccc;
        cursor: default;
      }
    }

    .react-datepicker__navigation--next {
      border-left-color: #ccc;

      &:hover {
        border-left-color: #ccc;
      }

      &--disabled,
      &--disabled:hover {
        border-left-color: #ccc;
        cursor: default;
      }
    }
  }
`;

export const EventResults = styled.h2`
  color: ${({ theme }) => theme.palette.white};
  letter-spacing: 0.08em;
`;

export const PaginationButton = styled.button`
  font-size: 24px;
  line-height: 1.6;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  color: ${({ theme }) => theme.palette.white};
  background: transparent;
  cursor: pointer;
  font-weight: 700;
  padding: 10px;
  transition: 0.3s ease-in-out;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: all !important;
  }

  ${({ previous }) => previous && css`
    svg {
      transition: transform 0.3s;
      transform: rotate(180deg);
      margin-right: 8px;
    }

    &:not([disabled]):hover, &:not([disabled]):focus {
      color: ${({ theme }) => theme.palette.secondaryHighlight};
      svg {
        transform: rotate(180deg) translateX(40%);
        polygon {
          fill: ${({ theme }) => theme.palette.white};
        }
      }
    }
  `}

  ${({ next }) => next && css`
    svg {
      transition: transform 0.3s;
      margin-left: 8px;
    }

    &:not([disabled]):hover, &:not([disabled]):focus {
      color: ${({ theme }) => theme.palette.secondaryHighlight};
      svg {
        transform: translateX(40%);
        polygon {
          fill: ${({ theme }) => theme.palette.white};
        }
      }
    }
  `}
`;

export const PaginationItems = styled.ul`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const FilterItem = styled.button`
  border-radius: 0;
  border: none;
  font-size: 1.125rem;
  display: inline-block;
  line-height: 1em;
  margin-right: .25em;
  padding: 8px 11px;
  white-space: nowrap;
  background-color: rgba(255,255,255,0.8);
  color: ${({ theme }) => theme.palette.primary};
  cursor: pointer;
  transition: background 0.3s;

  &:after {
    content: "+";
    display: inline-block;
    font-size: 1.5em;
    line-height: 1.125rem;
    margin-left: .15em;
    margin-top: 2px;
    transform: rotate(135deg);
    vertical-align: middle;
    color: ${({ theme }) => theme.palette.primary};
  }

  &:hover {
    background-color: rgba(255,255,255,1);
  }
`;

export const FilterWrapper = styled.div`
  padding-bottom: 10px;
`;
